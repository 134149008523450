import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../dummyData';

let url =  domainUrl



export const verifyPost= createAsyncThunk(
  'posts/show',
  async (code, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      // const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          // Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/v_verify?id=${code}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const createPost= createAsyncThunk(
  'posts/create',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      // const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          // Authorization: `Bearer ${persist.user.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.post(
        `${url}/v_post`,
        reqData,
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const editPost= createAsyncThunk(
  'posts/edit',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.put(
        `${url}/posts/${reqData.id}`,
        reqData,
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deletePost= createAsyncThunk(
  'posts/delete',
  async (postId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.delete(`${url}/posts/${postId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const showPost= createAsyncThunk(
  'posts/show',
  async (reqData, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      // const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          // Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/v_one_post?id=${reqData.postId}&&phone=${reqData.phone}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getPosts = createAsyncThunk(
  'posts/get',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      // const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          // Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/v_posts`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)