import React, { useState, useEffect } from "react";
import SlickHorizontalScroll from "../components/Horizontal/SlickHorizontalScroll";
import Header from "../components/Header/Header";
import PostCard from "../components/Cards/PostCard";
import { useDispatch } from "react-redux";
import { getPosts } from "../routes/PostAction";

const UserPosts = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
  const [showPhoneInput, setShowPhoneInput] = useState(!phone);

  const handleLoadPlus = () => {
    setPageNo(pageNo + 1);
  };
  const handleLoadMinus = () => {
    setPageNo(pageNo > 1 ? pageNo - 1 : 1);
  };

  const fetchData = () => {
    dispatch(getPosts({ cat: "all", searchword: "all", page: pageNo }))
      .then((response) => {
        let resData = response.payload.data;
        const editableData = resData?.map((o) => ({ ...o }));
        setPageData(editableData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (phone) {
      fetchData();
    }
  }, [phone, pageNo]);

  const handlePhoneSubmit = () => {
    if (phone) {
      localStorage.setItem("phone", phone);
      setShowPhoneInput(false);
      fetchData();
    }
  };

    const handlePhoneChange = (e) => {
        // Remove all non-digit characters
        let value = e.target.value.replace(/\D/g, '');

        // Add the '233' prefix if it's not there
        if (!value.startsWith('233')) {
            value = '233' + value;
        }

        // Trim the value to ensure it only has a total of 12 digits (including the '233' prefix)
        if (value.length > 12) {
            value = value.substring(0, 12);
        }

        setPhone(value);
    };

  return (
    <>
      <div className="divider"></div>
      <Header page={"post"} noSearch={false} />

      {showPhoneInput ? (
        <div className="phone-input-container d-flex justify-content-center">
            <div className="mt-2">
                <h6>Enter your phone number:</h6>
                <div className="row pt-2">
                    <input
                        style={{border:"solid 1px red", color:'red'}}
                        type="tel" 
                        className="mt-2"
                        value={phone}
                        onChange={handlePhoneChange}
                        // onChange={(e) => setPhone(e.target.value)}
                        placeholder="Enter phone number"
                    />
                    <button onClick={handlePhoneSubmit} className="col-3 btn btn-sm btn-danger mt-2 d-flex mx-auto justify-content-center">
                        Enter
                    </button>
                </div>
            </div>
          
        </div>
      ) : (
        <>
          {loading ? (
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="px-5">
              <SlickHorizontalScroll
                slidesToShow={pageData?.length <= 3 ? pageData?.length : null}
                data={pageData}
                card={PostCard}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserPosts;
