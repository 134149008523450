import moment from 'moment'

export const compDate = (date1, date2) =>{
  if (moment(date1).format('DD/MM/YYYY') === moment(date2).format('DD/MM/YYYY'))
    return true
  else
    return false
}

export const timeFormat = (time) =>{
  if (moment(time).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY'))
    return moment(time).format('hh:mm:ss a');
  else
    return moment(time).fromNow();
}

export const timeFormatNow = () => {
  const timenow = new Date(); // Set timenow to the current date and time
  return moment(timenow).fromNow();
}

export const timeTable = (value) => {
  return moment(value).format('YYYY-MM-DD')
  // return moment(value).format('dddd, MMMM Do, YYYY h:mm:ss A')
}

export const fullTime = (value) => {
  return moment(value).format('dddd, MMMM Do, YYYY h:mm:ss A')
}

export const dateTime = (value) => {
  return moment(value).format('dddd, MMMM Do, YYYY')
}

// Check if a date is today
export const isToday = (date) => {
  return moment(date).isSame(moment(), 'day');
};

// Check if a date is within this week
export const isThisWeek = (date) => {
  return moment(date).isSame(moment(), 'week');
};

// Check if a date is within this month
export const isThisMonth = (date) => {
  return moment(date).isSame(moment(), 'month');
};

export const reduceText = (text, length, suffix) => {
  if (text?.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text;
  }
}

export const domain = "https://nana-frimpomaa.com/"; //server
// export const domain = "http://127.0.0.1:8001/"; //dev

export const domain2  = "https://nana-frimpomaa.com"; //server
// export const domain2  = "http://127.0.0.1:8001"; //dev

export const domainUrl = "https://nana-frimpomaa.com/api"; //server
// export const domainUrl = "http://127.0.0.1:8001/api"; //dev
