import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../dummyData';

let url = "http://127.0.0.1:8000/api"

url =  domainUrl

export const editChat= createAsyncThunk(
  'chat/edit',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          // Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.put(
        `${url}/chats/${reqData.id}`,
        reqData,
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getFlags = createAsyncThunk(
  'chat/flags',
  async (phone, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          // Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/v_ads`, config)

      // const { data } = await axios.get(`${url}/sessions?cat=${reqData.cat}&&searchword=${reqData.searchword}&&page=${reqData.page}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getChatHistory = createAsyncThunk(
  'chat/chat_history',
  async (phone, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          // Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/gtp_chats?phone=${phone}`, config)

      // const { data } = await axios.get(`${url}/sessions?cat=${reqData.cat}&&searchword=${reqData.searchword}&&page=${reqData.page}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const sendChatMessage = createAsyncThunk(
  'chat/send_message',
  async (reqData, { getState, rejectWithValue }) => {
    try {
      // get user data from store
    const { persist } = getState()
    
    // configure authorization header with user's token
    const config = {
      headers: {
        // Authorization: `Bearer ${persist.user.userToken}`,
        'Content-Type': 'multipart/form-data',
      },
    }

      const { data } = await axios.post(
        `${url}/v_chat`,
        reqData,
        config
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const sendMessage = createAsyncThunk(
  'chat/send_message',
  async (reqData, { getState, rejectWithValue }) => {
    try {
      // get user data from store
    const { persist } = getState()
    
    // configure authorization header with user's token
    const config = {
      headers: {
        // Authorization: `Bearer ${persist.user.userToken}`,
        'Content-Type': 'multipart/form-data',
      },
    }

      const { data } = await axios.post(
        `${url}/gpt_chat`,
        reqData,
        config
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)