import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, timeTable, domain } from "../../dummyData";

import {  verifyPost, editPost, createPost } from "../../routes/PostAction";

import CustomAlert from '../Alert/CustomAlert';

const CreatePageModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  const [code, setCode] = useState('');
  const [showSubmit, setShowSubmit] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleOpen = () => {
    setShowSubmit(false);
    setCode('')
    setShow(true)
    
  };

  const handleClose = () => {
    setShow(false)
  };

  // console.log('d', props?.title)
  // console.log('allp', props)
  const dispatch = useDispatch();

  const customAlertRef = useRef()
  
  const navigate = useNavigate();
  const navigateToRoute = (url) => {
      navigate(url);
  };

  const [pageFormData, setPageFormData] = useState({
    title: '',
    about: '',
    msg: '',
    image: '',
    terms: 0,
    total: '0.00',
    tax: '0.00',
    status: 0,
  });

  const handleFormChange = (e) => {
    if (e.target.name === 'status') {
      setPageFormData({ ...pageFormData, [e.target.name]: e.target.checked ? 1 : 0 });
    } else if(e.target.name === 'image') {

      const files = e.target.files;
      if (files && files.length > 0) {
          const file = files[0];
          setPageFormData({ ...pageFormData, [e.target.name]: file });
      } else {
          setPageFormData({ ...pageFormData, [e.target.name]: '' });
          console.error("No file selected");
      }
    } else {
      setPageFormData({ ...pageFormData, [e.target.name]: e.target.value });
    }
  }

  const submitData = () => {
    // console.log('newd', pageFormData)
    if(pageFormData.title === '') {
        return customAlertRef.current.handleOpen("Form filled incorrectly", 'danger');
    } else {
      return pageFormData.id > 0? 
        dispatch(editPost(pageFormData))
        : dispatch(createPost(pageFormData))
        .then(response =>{
          // if (error) {
          //   customAlertRef.current.handleOpen(error, 'danger');
          
          // } else {
          //     if (successMessage !== '') {
          //         customAlertRef.current.handleOpen(successMessage, 'success');
          //     }
          // }
          setShow(false);
        })
      .catch((error) => {
        console.log(error)
        customAlertRef.current.handleOpen(error, 'danger');
      })
    }
    
  }


  const submitCode = () => {

    if(code !== '') {
      dispatch(verifyPost(code))
        .then(response =>{
          // console.log('verify', response.payload);

          if (response.payload.success) {
            setShowSubmit(true);
          } else {
            setShowSubmit(false);
          }
        })
      .catch((error) => {
        console.log(error)
        // customAlertRef.current.handleOpen(error, 'danger');
      })
    }
    
  }


  return (
    <div onClick={() => handleClose}>
      <CustomAlert ref={customAlertRef}/>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props?.eDate? timeTable(props?.eDate): 'View'}</Modal.Title> */}
          <Modal.Title>{props?.title?props?.title: 'View'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="d-flex justify-content-center my-2 flex-column">
            <input value={pageFormData.title} onChange={handleFormChange} className="form-control form-control-sm" required type="text" placeholder="Title" name='title'/> 

            <input onChange={handleFormChange} className="form-control form-control-sm mt-1" type="file" placeholder="image" name='image' required/>

            <input value={pageFormData.about? pageFormData.about: ''} onChange={handleFormChange} className="form-control form-control-sm mt-1" type="text" placeholder="about" name='about' required/>

            <input value={pageFormData.msg? pageFormData.msg: ''} onChange={handleFormChange} className="form-control form-control-sm mt-1" type="text" placeholder="Message" name='msg' required/>

            <div className='d-flex justify-content-center my-2 mx-auto flex-row'>
              To create a post, email us: (info@votersalert.com) for code.
              
            </div>
            
          </div>

        </Modal.Body>

        <Modal.Footer>
          
          
          {showSubmit == true?  <div className=''>

            <span onClick={submitData} style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="badge px-3 py-2 my-1 rounded-pill mr-2">
              Submit
            </span>
            <span style={{border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleClose}>
              Cancel
            </span>

          </div> :
            <div className='d-flex justify-content-center mx-auto'>
                <input value={code} onChange={(e) => setCode(e.target.value)} className="mr-2 col-4" type="text" placeholder="Enter code" name='code' required/>
                <span onClick={submitCode} style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="badge px-3 py-2 my-1 mr-2">
                  Verify
                </span>
            </div>
          }
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default CreatePageModal