import React, { useState, useRef, useEffect } from "react"

// import { updateKeyWord } from '../../redux/UserSlice';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// import {logout, userSub} from '../../routes/UserAction';
import './header.css'
// import Guide from "../../Dashboard/Form/Guide";
import TipsModal from '../../components/Modal/TipsModal';
import CreatePageModal from "../Modal/PageModal";
import DonateModal from "../Modal/DonateModal";

import {getFlags} from '../../routes/ChatAction';

import { domain, reduceText } from '../../dummyData';
let url =  domain;



const Header = (props) => {

  const dispatch = useDispatch();
  
  // const { searchWord, loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  // const userData = user;

  const { newChat } = useSelector((state) => state.chat)

  const handleClick = () => { 
    // dispatch(logout()); 
  };

  // const handleSearch = (e) => {
  //   dispatch(updateKeyWord(e.target.value))
  // }

  const [searchText, setSearchText] = useState('');
  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleSearchS = () => {
    // console.log('cahaa')
    // dispatch(updateKeyWord(searchText))
  }

  const handleSearchC = () => {
    setSearchText('')
    // dispatch(updateKeyWord(''))
  }

  const navigate = useNavigate();
  const navigateToRoute = (url) => {
    // 👇️ navigate to /contacts
    navigate(url);
  };

  const [scrollFlagData, setScrollFlagData] = useState([]);
  const fetchFlags = () => {
    dispatch(getFlags())
    .then(response =>{
      let resData = response?.payload?.data;
      const editableData = resData?.map(o => ({ ...o }));

      // setScrollFlagData(editableData?.filter(el => (el.name === 'app-scroll')))

      setScrollFlagData(editableData);

      // console.log(sessionFlagData)

    })
    .catch((error) => {
        console.log(error)
    })
  }

  useEffect(() => {
    fetchFlags();
  }, [])

  const [onMobileState, setOnMobileState] = useState(false);

  const mobileMenuClickHandler = (menu) => {
    setOnMobileState(onMobileState => !onMobileState)
  };

  const guideRef = useRef()

  const pageRef = useRef()
  const modalRef = useRef()
  const donateRef = useRef()
  const handleOpenDonate = () => {
    donateRef.current.handleOpen();
    
  };
  const handleOpen = () => {
    modalRef.current.handleOpen();
    
  };
  const handleOpenPage = () => {
    pageRef.current.handleOpen();
    
  };

  return (
    <>
    {/* <header> */}

    <header className="section-header fixed-top">
      <TipsModal 
        title={' Tips'}
        // tableForm={<Guide 
        //   // handleResponse={handleResponse}
        //   ref={guideRef} 
        // />} 
        ref={modalRef}
        // handleSubmit={''}
        
      />
      <CreatePageModal 
        title={' Create Post'}
        ref={pageRef}
        // handleSubmit={''}
        
      />
      <DonateModal 
        title={' Donate Now'}
        ref={donateRef}
        // handleSubmit={''}
        
      />

      <nav className="navbar navbar-light navbar-expand-lg p-2 bg-white">
        <div className="container-fluid">
          
          <ul className="navbar-nav d-flex align-items-center flex-row">
            <button style={{border:'white solid 1px'}} onClick= {mobileMenuClickHandler} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              {/* <span style={{color:'#ec2f2f'}} className="navbar-toggler-icon"></span> */}
              <i style={{color:'#ec2f2f'}} className="fa fa-bars"></i>
            </button>
            {!onMobileState? 
              <li className="nav-item mx-2">
                <div className="d-flex">
                  <Link to={'/'} className="">
                    <img alt='ddk' src="./assets/images/logo/logo.png" className="" width="50"></img>
                  </Link>
                    
                </div>
              </li>
              : 
              <></>}
            
            
          </ul>

          <div className="collapse navbar-collapse px-5" id="navbarNavDropdown">

            <ul className="navbar-nav">
              <li className="nav-item mx-2">
                <Link style={{textDecorationLine:props.page==='home'? "underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/'} className="nav-link active" aria-current="page"><h6 style={{color:'#ec2f2f'}}>Home</h6></Link>
                {/* <a className="nav-link active" aria-current="page" href="s#">Electronics</a> */}
              </li>
              <li className="nav-item mx-1">
                <Link style={{textDecorationLine:props.page==='post'? "underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/posts'} className="nav-link" href="s#"><h6 style={{color:'#ec2f2f'}}>posts</h6></Link>
              </li>
              <li className="nav-item mx-1">
                <Link style={{textDecorationLine:props.page==='votersgpt'?"underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/votersgpts'} className="nav-link"><h6 style={{color:'#ec2f2f'}}>Ask GPT</h6></Link>
              </li>

              {/* <li className="nav-item mx-1">
                <Link style={{textDecorationLine:props.page==='ghana_bas'?"underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/ghana_ba_shares'} className="nav-link" data-abc="true"><h6 style={{color:'#ec2f2f'}}>Donate</h6></Link>
              </li> */}
              
            </ul>
          </div>

            {/* <span onClick={handleOpenDonate} className="border-blue input-group-text bg-white text-blue mr-3">
              <span className="text">Donate</span>
            </span> */}
            {/* <span onClick={() => window.open("https://paystack.com/pay/nanafrimpomaa", "_blank")} className="border-blue input-group-text bg-white text-blue mr-3">
              <span className="text">Donate</span>
            </span> */}
          

          {!onMobileState? 
            <ul className="navbar-nav d-flex align-items-center flex-row">
              <i style={{fontSize: 24, color:'#ec2f2f'}} onClick={handleOpenPage} className="fa fa-add mr-3"></i>

              <i style={{fontSize: 24, color:'#ec2f2f'}} onClick={handleOpen} className="fa fa-bell mr-1"></i>


              {/* <li className="nav-item mx-2">
                <div className="d-flex">
                  <Link to={(userData?.name !== null? `/edit_profile?id=${userData?.uuid}`: '/landing_page')}>
                    <img style={{border:'#ec2f2f 3px solid', width:'10vw', height:'10vw', maxWidth:'30px', maxHeight:'30px'}} alt='ddk' src= {userData?.image? url+userData?.image:"./assets/images/1.jpg"} className="rounded-circle"></img>
                  </Link>
                </div>
              </li>
              {(userData?.name !== '' || userData?.name !== null)?<li className="nav-item mx-1">
                <div className="">
                  <button onClick={handleClick} style={{backgroundColor:'#ec2f2f', border:'#F9CC2E 1px solid', color:'#F9CC2E'}} className="btn btn-sm b-screen" type="button">Logout</button>
                  <i onClick={handleClick} style={{color:'#ec2f2f'}} className="m-screen fa fa fa-sign-in"></i>
                </div>
              </li>: <></>} */}
            
            </ul>
          : 
            <></>
          }
          
        </div> 
      </nav>

      {/* <section className="border-bottom bg-white"> */}
        {props.noSearch? <></>:
        <div className="px-3">
          <div className="row p-2 pt-3 pb-2 d-flex align-items-center">

          <marquee style={{color:'#ec2f2f'}} width="100%" direction="left">
            {scrollFlagData?.map((row, index) => (
              <Link key={row?.id} style={{}} to={row.display_name} className="">
                <img 
                  style={{ border: '#ec2f2f 3px solid', width: '100px', height: '100px'}} 
                  alt='Preview' 
                  src={domain+row?.url} 
                  className="mr-1" 
                />
              </Link>
              ))
            }

              <a style={{ border: '#ec2f2f 3px solid', width: '140px', height: '100px', paddingTop: '30px'}}
              href="mailto:info@votersalert.com" className="btn btn-sm ml-2 bg-danger text-white">
                <span className="" style={{textAlign: 'center'}}>Submit Ads <h6 style={{fontSize: 12}}>(info@votersalert.com)</h6> </span>
              </a>

              <Link style={{ border: '#ec2f2f 3px solid', width: '100px', height: '100px'}} onClick={() => window.open("https://paystack.com/pay/voters-alert", "_blank")} className="btn btn-sm ml-2 bg-danger text-white">
                <h6 className="" style={{textAlign: 'center', paddingTop: '40%'}}>Support US</h6>
              </Link>
          </marquee>
              
            
          </div>
        </div>}
      {/* </section> */}
    </header>
    </>
  )
}

export default Header
