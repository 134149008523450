import React, { useImperativeHandle, useState, forwardRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

const DonateModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [media, setMedia] = useState('');

  
  const handleOpen = (title, media) => {
    setTitle(title)
    setMedia(media)
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };

  useImperativeHandle(
    ref,
    () => ({
      handleOpen(title, media) {handleOpen(title, media)},
    }),
  )

  const donateNow = (amount) => {
    // https://paystack.com/pay/nanafrimpomaa?amount=100&&email=emjay@gmail.com&&phone=0559148214&&name=Yakubu%20Jamiu

    // https://paystack.com/pay/nanafrimpomaa?amount=100&&email=emjay@gmail.com&&phone=0559148214&&name=Yakubu Jamiu

    // https://paystack.com/pay/nanafrimpomaa

    // https://paystack.com/pay/nanafrimpomaa?amount=100

    // https://paystack.com/pay/nanafrimpomaa?phone=0559148214
  }


  return (
    <div onClick={() => handleClose}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Donate now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-center'>
            {/* <img
              alt="view"
              src={media}
              className="img-fluid"
              style={{ maxHeight: '100%', maxWidth: '100%' }}
            /> */}

          <Button variant="danger" onClick={handleClose}>
            GHS 5
          </Button>
          <Button variant="danger" onClick={handleClose}>
            GHS 10
          </Button>
          <Button variant="danger" onClick={handleClose}>
            GHS 50
          </Button>
          <Button variant="danger" onClick={handleClose}>
            GHS 100
          </Button>
          <Button variant="danger" onClick={handleClose}>
            GHS 500
          </Button>
          <Button variant="danger" onClick={handleClose}>
            GHS 1,000
          </Button>
          <Button variant="danger" onClick={handleClose}>
            GHS 5,000
          </Button>
          <Button variant="danger" onClick={handleClose}>
            GHS 10,000
          </Button>

          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default DonateModal