import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { useDispatch, useSelector } from 'react-redux'
// import {getFlags} from '../../routes/FlagAction';

import { domain } from '../../dummyData';
let url =  domain;

const TipsModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };


  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleSubmit = () => {
    props.handleSubmit()
  };

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(1);
  const [indexNo, setIndexNo] = useState(0);

  const [msgFlagData, setMsgFlagData] = useState([]);

  const handleSelected = (e) => {
    // setSelected(e.target.value)
    setIndexNo(1);
    setSelected(parseInt(e.target.value, 10));
    // console.log(selected)
  };

  const fetchData = () => {
    // dispatch(getFlags())
    // .then(response =>{
    //   let resData = response.payload.data;
    //   const editableData = resData.map(o => ({ ...o }));

    //   setMsgFlagData(editableData?.filter(el => (el.name === 'app-msg')))

    // })
    // .catch((error) => {
    //     console.log(error)
    // })
  }

  useEffect(() => {

      fetchData()

  }, [])


  return (
    <div onClick={() => handleClose}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Messages</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="d-flex justify-content-center my-2 flex-column">
            <div className=''>
              To create a post or ads, email us: (info@votersalert.com) for code.

              
            </div>
            <span onClick={() => window.open("https://paystack.com/pay/voters-alert", "_blank")} className="col-4 mx-auto btn btn-sm bg-danger text-white">
                <span className="text">Support Us</span>
              </span>
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div>
            <span onClick={handleClose} style={{color:'red', border:"solid 1px red"}} className="badge rounded-circle">
              <i className="fa fa-close"></i>
            </span>
          </div>
          
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default TipsModal