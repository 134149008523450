import React, { useState, useRef } from 'react';

import { timeFormat, domain } from "../../dummyData";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import SubPageModal from '../Modal/SubPageModal';

const PostCard = (props) => {
    // const { user } = useSelector((state) => state.persist.user)
    // const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const addPageRef = useRef()
    const handleOpenPage = () => {
        addPageRef.current.handleOpen();
        
    };

    return (
        <>
            <SubPageModal
                title={' Extend Post'}
                ref={addPageRef}
                data={props.data}
                // handleSubmit={''}
                
            />

            {/* improvement area */}

            {/* <div onClick={() => {navigateToRoute(`/chat?id=${props.data?.chat?.uuid}&&code=${props.data?.user?.uuid}&&type=post`)}} className="row mx-2"></div> */}
            
            <div className="row mx-2">
                <div className="card text-bg-white mb-3 mx-2" style={{maxWidth:"18rem"}}>
                    <div className="d-flex justify-content-center my-2">
                        <img style={{width:'20vh', height:'20vh', marginTop:'10px'}} alt='ddk' src={props.data?.image? domain+props.data.image: "./assets/images/1.jpg"} className="rounded-circle"></img>
                    </div>
                    {/* <div className="d-flex justify-content-center my-2">
                        {(props.data.user?.user_id === userData.id)? 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>You</h6> 
                        : 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>{props.data.user? props.data?.user?.first_name: ''} {props.data?.user? props.data?.user?.last_name: ''}</h6>
                        }
                    </div> */}
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#F9CC2E', fontSize:12}}>Posted {timeFormat(props.data?.user?.created_at)}</h6>
                    </div>

                    {/* <h6 className="mx-auto" style={{marginBottom:'0', marginTop:'0', color:'#ec2f2f', marginLeft:'10px', fontSize: 12, fontStyle: 'italic', textAlign: 'center'}}>
                        {props.data?.user?.city? props.data?.user?.city+',': ''} {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                    </h6> */}
                    <h6 className="mx-auto" style={{marginBottom:'0', marginTop:'0', color:'#ec2f2f', marginLeft:'10px', fontSize: 12, fontStyle: 'italic', textAlign: 'center'}}>
                        {props.data?.title? props.data?.title: ''}
                    </h6>

                    {/* <div className='d-flex flex-row mx-auto'>
                        <span style={{}} className="badge py-2 px-1 my-1 rounded">
                            <i onClick={handleOpenPage} style={{color:'#ec2f2f', fontSize:22}} className="fa-solid fa-share mr-3"></i>
                        </span>
                        <span style={{}} className="badge py-2 px-1 my-1 rounded">
                            <i onClick={() => {navigateToRoute(`/chat?id=${props.data?.uuid}&&code=${props.data?.chat?.uuid}&&type=post`)}} style={{color:'#ec2f2f', fontSize:22}} className="fa-solid fa-message ml-3"></i>
                        </span>
                    </div> */}

                        <div className='d-flex flex-row mx-auto'>
                            <span style={{}} className="badge py-2 px-1 my-1 rounded">
                                <i onClick={() => {navigateToRoute(`/chat?id=${props.data?.uuid}&&code=${props.data?.chat?.uuid}&&type=post`)}} style={{color:'#ec2f2f', fontSize:22}} className="fa-solid fa-eye ml-3"></i>
                            </span>
                        </div>
                        
                </div>
            </div>
        </>
    );

}

export default PostCard