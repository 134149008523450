import "./App.css"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"

import Chat from "./home/Chat"
import Post from "./home/UserPosts"
import PostChat from "./home/PostChat"

function App() {

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Post />} />
        <Route exact path='/posts' element={<Post/>} />

        <Route exact path='/votersgpts' element={<Chat/>} />
        <Route exact path='/chat' element={<PostChat/>} />
        
        <Route path="*" element={<Post/>} />
      </Routes>
    </Router>
  );
}

export default App
